import { descrJobText } from 'Design';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';

export const Description = ({ text }) => {
  return <Text>{text}</Text>;
};

const Text = styled.p`
  text-align: center;
  ${descrJobText}
  color: #B3B3B3;
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 22px;
  }
`;
