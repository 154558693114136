import { hover, passed, tabHeader } from 'Design';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';

export const Text = ({ text }) => {
  return <StyledText className='text'>{text}</StyledText>;
};

const StyledText = styled.p`
  ${tabHeader}
  &:hover {
    color: ${hover};
  }
  &:active {
    color: ${passed};
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 22px;
  }
`;
