import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BackToPage, File, Loader } from 'components';
import { useQuery } from '@apollo/client';
import {
  Col,
  Container,
  Row,
  btnSecSm,
  h3h,
  h5h,
  tabHeader,
  txtLg,
  txtSm,
  txtXm,
  txtXs
} from 'Design';
import { GET_PURCHASE } from 'api/queries';
import styled from 'styled-components';
import { BREAKPOINTS, formatDateFromStrapi } from 'utils';

export const OnePurchasePage = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_PURCHASE, {
    variables: { id: id }
  });

  const dataQuery = useMemo(() => {
    if (data) {
      return data.purchase?.data;
    }

    return null;
  }, [data]);

  if (loading) {
    return (
      <Row justifyContent={'center'} wide alignCenter height={'600px'}>
        <Loader />
      </Row>
    );
  }

  const statusText =
    dataQuery.attributes.status === 'active'
      ? 'Активен'
      : dataQuery.attributes.status === 'archive'
      ? 'В архиве'
      : '';

  const statusColor =
    dataQuery.attributes.status === 'active'
      ? 'green'
      : dataQuery.attributes.status === 'archive'
      ? 'grey'
      : '';

  if (dataQuery && !error) {
    return (
      <Wrapper
        maxWidth={1180}
        paddingX={32}
        paddingTop={116}
        xlPaddingTop={112}
        smPaddingTop={96}
        smPaddingX={16}
      >
        <BackToPage text={'Назад к списку закупок'} link={'/purchases'} />

        <StyledRow paddingTop={40} smPaddingTop={30} alignCenter nowrap>
          <Number>
            Закупка {`\u2116`}
            {dataQuery.attributes.number}
          </Number>

          <Status color={statusColor}>{statusText}</Status>
        </StyledRow>

        <Date>
          Дата публикации {formatDateFromStrapi(dataQuery.attributes.date)}
        </Date>

        <StyledMainRow
          paddingTop={40}
          lgPaddingTop={64}
          smPaddingTop={48}
          nowrap
          spaceBetween
        >
          <StyledCol width={'780px'}>
            <Subtitle paddingTop={'unset'}>Организатор</Subtitle>

            <Text>{dataQuery.attributes.organizer}</Text>

            <Subtitle lgPaddingTop={48} smPaddingTop={32}>
              Срок подачи предложения
            </Subtitle>

            <Text>{`от ${formatDateFromStrapi(
              dataQuery.attributes.start_deadline
            )} до ${formatDateFromStrapi(
              dataQuery.attributes.end_deadline
            )}`}</Text>

            <Subtitle lgPaddingTop={48} smPaddingTop={32}>
              Предмет закупки
            </Subtitle>

            <Text>{dataQuery.attributes.description}</Text>

            {dataQuery.attributes.documents?.data.length !== 0 && (
              <Container hidden lgVisible lgPaddingTop={48} smPaddingTop={32}>
                <Subtitle paddingTop={'unset'} document>
                  Документы
                </Subtitle>

                <StyledDocumentRow>
                  {dataQuery.attributes.documents?.data.map((item, index) => (
                    <File
                      title={item.attributes.name}
                      ext={item.attributes.ext}
                      size={item.attributes.size}
                      url={item.attributes?.url}
                      key={index}
                    />
                  ))}
                </StyledDocumentRow>
              </Container>
            )}
            {dataQuery.attributes.mail && (
              <>
                <Subtitle
                  paddingTop={'60px'}
                  lgPaddingTop={64}
                  smPaddingTop={48}
                >
                  Почта для подачи заявок
                </Subtitle>

                <Text>{dataQuery.attributes.mail}</Text>
              </>
            )}
          </StyledCol>

          {dataQuery.attributes.documents?.data.length !== 0 && (
            <StyledCol width={'280px'} lgHidden>
              <Subtitle paddingTop={'unset'} document>
                Документы
              </Subtitle>

              <StyledDocumentRow>
                {dataQuery.attributes.documents?.data.map((item, index) => (
                  <File
                    title={item.attributes.name}
                    ext={item.attributes.ext}
                    size={item.attributes.size}
                    url={item.attributes?.url}
                    key={index}
                  />
                ))}
              </StyledDocumentRow>
            </StyledCol>
          )}
        </StyledMainRow>
      </Wrapper>
    );
  }
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const StyledCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.lg}) {
    max-width: unset;
  }
`;

const StyledMainRow = styled(Row)`
  gap: 40px;
  @media (max-width: ${BREAKPOINTS.lg}) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 48px;
  }
`;

const StyledRow = styled(Row)`
  gap: 24px;
  @media (max-width: ${BREAKPOINTS.sm}) {
    gap: 16px;
    flex-wrap: wrap;
  }
`;

const Number = styled.p`
  ${h3h};
  @media (max-width: ${BREAKPOINTS.sm}) {
    ${txtLg};
    font-weight: 700;
  }
`;

const Status = styled.p`
  padding: 4px 16px;
  ${btnSecSm};
  color: #ffffff;
  border-radius: 8px;
  background-color: ${(p) =>
    p.color === 'green' ? '#166715' : p.color === 'grey' ? '#8A8A8A' : ''};
  @media (max-width: ${BREAKPOINTS.sm}) {
    padding: 4px 12px;
    ${txtXs};
  }
`;

const Date = styled.p`
  padding-top: 20px;
  ${tabHeader};
  @media (max-width: ${BREAKPOINTS.lg}) {
    padding-top: 16px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    ${txtXs};
    font-weight: 400;
  }
`;

const Subtitle = styled.p`
  padding-top: ${(p) => (p.paddingTop ? p.paddingTop : '40px')};
  ${h5h};
  margin-bottom: ${(p) => (p.document ? '24px' : '')};
  @media (max-width: ${BREAKPOINTS.lg}) {
    padding-top: ${(p) => (p.lgPaddingTop ? `${p.lgPaddingTop}px` : '')};
    margin-bottom: ${(p) => (p.document ? '16px' : '')};
  }
  @media (max-width: ${BREAKPOINTS.lg}) {
    padding-top: ${(p) => (p.smPaddingTop ? `${p.smPaddingTop}px` : '')};
  }
`;

const Text = styled.p`
  padding-top: 20px;
  ${txtXm};

  @media (max-width: ${BREAKPOINTS.lg}) {
    padding-top: 16px;
  }
  @media (max-width: ${BREAKPOINTS.lg}) {
    ${txtSm};
    font-weight: 400;
  }
`;

const StyledDocumentRow = styled(Row)`
  flex-direction: column;
  gap: 16px;
  @media (max-width: ${BREAKPOINTS.lg}) {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;
