import { txtXm } from 'Design';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';

export const Position = ({ text }) => {
  return <Text>{text}</Text>;
};

const Text = styled.p`
  text-align: center;
  ${txtXm};
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 16px;
    line-height: 22px;
  }
`;
