import { Container, h1h, h2h, h3h, h5h, Row, white } from 'Design';
import React, { useState } from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';
import bg from 'images/Jobs/bg.jpg';
import shadow from 'images/Jobs/shadow.png';
import { VacancyButton, PrimaryButton, BackToPage, Loader } from 'components';
import { useQuery } from '@apollo/client';
import { GET_ALL_VACANCIES } from 'api/queries';

export const JobsPage = () => {
  const [limit, setLimit] = useState(10);

  const { loading, data, error } = useQuery(GET_ALL_VACANCIES);

  if (loading) {
    return (
      <Row justifyContent={'center'} wide alignCenter height={'600px'}>
        <Loader />
      </Row>
    );
  } else if (error) {
    return <p>Something went wrong...</p>;
  }

  const vacancy = data.vacancies?.data;

  const showMore = () => {
    setLimit((prev) => prev + 5);
  };
  return (
    <div>
      <BG>
        <Shadow>
          <Wrapper
            maxWidth={1180}
            paddingTop={116}
            paddingX={32}
            mdPaddingX={16}
            xlPaddingTop={112}
            smPaddingTop={96}
          >
            <BackToPage text='Назад на главную' link='/' light />
            <StyledRow
              marginTop={'-115'}
              mdMarginTop={'-120'}
              smMarginTop={'-90'}
              justifyContent={'center'}
              alignCenter
              width={'100%'}
              height={'586px'}
              direction={'column'}
            >
              <Description>Стань частью нашей команды</Description>
            </StyledRow>
          </Wrapper>
        </Shadow>
      </BG>

      <Wrapper
        maxWidth={1180}
        paddingTop={40}
        paddingX={130}
        xlPaddingX={32}
        mdPaddingX={16}
        mdPaddingTop={30}
      >
        <List direction={'column'} maxWidth={980}>
          {vacancy.slice(0, limit).map((item, index) => (
            <VacancyButton
              key={index}
              title={item.attributes.title}
              id={item.id}
            />
          ))}
        </List>
      </Wrapper>
      {limit > vacancy.length ? null : (
        <Row justifyContent='center' marginTop={60}>
          <PrimaryButton
            onClick={() => showMore()}
            title='Смотреть еще'
            large
          />
        </Row>
      )}
    </div>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const BG = styled.div`
  width: 100%;
  height: 586px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 384px;
  }
`;

const Shadow = styled(BG)`
  height: 586px;
  /* background-image: url(${shadow}); */
  background: linear-gradient(
    93.63deg,
    rgba(19, 19, 19, 0.48) 49.67%,
    rgba(19, 19, 19, 0) 85.33%
  );
  /* filter: blur(2px); */
  @media (max-width: ${BREAKPOINTS.md}) {
    background-position: center;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 385px;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 384px;
    align-items: flex-start;
  }
`;

const Title = styled.span`
  ${h5h};
  background: linear-gradient(272.25deg, #2e61e3 2.99%, #30cae2 96.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const Description = styled.p`
  margin-bottom: 24px;
  ${h3h};
  color: ${white};
  @media (max-width: ${BREAKPOINTS.xl}) {
    /* ${h2h} */
    text-align: center;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    text-align: start;
    font-size: 24px;
    line-height: 29px;
    padding-left: unset;
  }
`;

const List = styled(Row)`
  margin: 0 auto;
  gap: 32px;
  @media (max-width: ${BREAKPOINTS.xl}) {
    gap: 24px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    gap: 16px;
  }
`;
