import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Arrow } from 'components';
import { btnPimSm, primary, white } from 'Design';
import { BREAKPOINTS } from 'utils';

export const BackToPage = ({ text, link, light }) => {
  return (
    <ButtonLink to={link}>
      <Arrow left />
      <Text light={light}>{text}</Text>
    </ButtonLink>
  );
};

const Text = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: ${(p) => (p.light ? white : primary)};
  ${btnPimSm};
  font-weight: 400;
  background: none;
  border: none;
  outline: none;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  opacity: ${(p) => (p.disabled ? '0.4' : '1')};
`;

const ButtonLink = styled(Link)`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;

  &:hover ${Text} {
    color: #2e61e3;
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    & div div {
      width: 24px;
      height: 24px;
    }
  }
`;
