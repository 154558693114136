import React from 'react';
import { MainSlider } from 'components';

export const MainScreen = () => {
  return (
    <MainSlider
      videoId='IQ-6HN0WaWM'
      center
      title='Мы не просто строим дороги – мы строим их на века!'
      body='Кардинально обновляем подход к строительству дорог в России,
              создаем собственную систему быстрого и эффективного строительства
              дорожных магистралей, с последующим их обслуживанием'
    />
  );
};
