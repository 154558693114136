import { h5h, hover, passed } from 'Design';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';

export const Title = ({ middle = false, small = false, title }) => {
  return (
    <Text middle={middle} small={small} className='title'>
      {title}
    </Text>
  );
};

const Text = styled.p`
  ${h5h}
  ${(p) =>
    p.middle || p.small
      ? `font-size: 20px;
        line-height: 24px;`
      : ''}
    &:hover {
    color: ${hover};
  }
  &:active {
    color: ${passed};
  }
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 24px;
    line-height: 29px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 20px;
    line-height: 24px;
  }
`;
