import { Container, h3h, h5h } from 'Design';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';
import { ProjectSlider } from 'components';
import img1 from 'images/Home/Projects/ill1.JPG';
import img2 from 'images/Home/Projects/ill2.JPG';
import img3 from 'images/Home/Projects/ill3.JPG';
import img4 from 'images/Home/Projects/ill4.JPG';
import img5 from 'images/Home/Projects/ill5.JPG';
import img6 from 'images/Home/Projects/ill6.JPG';
import img7 from 'images/Home/Projects/ill7.JPG';
import img8 from 'images/Home/Projects/ill8.JPG';

const sliderImages = [img1, img2, img3, img4, img5, img6, img7, img8];

export const Projects = (props) => {
  return (
    <>
      <Wrapper {...props} maxWidth={1180}>
        <Title>Наши проекты</Title>
        <Container smHidden>
          <Container marginBottom={40} lgMarginBottom={30} smMarginBottom={20}>
            <ProjectSlider
              title='ЮЖНО-ЛЫТКАРИНСКАЯ АВТОДОРОГА'
              body='Южно-Лыткаринская автодорога - знаковый и приоритетный инфраструктурный объект, который кардинально улучшит транспортную доступность юго-востока Подмосковья.'
              link='/'
              animations={sliderImages}
            />
          </Container>
        </Container>
      </Wrapper>
      <Container hidden smVisible>
        <Container smMarginBottom={20}>
          <ProjectSlider
            title='ЮЖНО-ЛЫТКАРИНСКАЯ АВТОДОРОГА (ЮЛА)'
            body='Южно-Лыткаринская автодорога - знаковый и приоритетный инфраструктурный объект, который кардинально улучшит транспортную доступность юго-востока Подмосковья.'
            link='/'
            animations={sliderImages}
          />
        </Container>
      </Container>
    </>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const Title = styled.span`
  margin-bottom: 60px;
  display: inline-block;
  ${h5h}
  background: linear-gradient(272.25deg, #2E61E3 2.99%, #30CAE2 96.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 14px;
    line-height: 17px;
  }
`;
