import React from 'react';
import { Map as YandexMap, Placemark } from '@pbe/react-yandex-maps';

export const Map = ({ height }) => {
  return (
    <YandexMap
      width={'100%'}
      height={height}
      defaultState={{ center: [55.633785, 37.431989], zoom: 16 }}
    >
      <Placemark defaultGeometry={[55.633785, 37.431989]} />
    </YandexMap>
  );
};
