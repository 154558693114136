import React from 'react';
import styled from 'styled-components';
import {
  Col,
  Container,
  Row,
  h5h,
  hover,
  latoFn,
  txtMd,
  txtSm,
  txtXm
} from 'Design';
import { BREAKPOINTS } from 'utils';
import { useQuery } from '@apollo/client';
import { GET_PREQUALIFICATION_DOCUMENTS } from 'api/queries';
import { useMemo } from 'react';
import { File, Loader } from 'components';

export const Qualification = () => {
  const { loading, error, data } = useQuery(GET_PREQUALIFICATION_DOCUMENTS);

  const dataQuery = useMemo(() => {
    if (data) {
      return data.prequalifications?.data[0];
    }

    return null;
  }, [data]);

  if (loading) {
    return (
      <Row justifyContent={'center'} wide alignCenter height={'600px'}>
        <Loader />
      </Row>
    );
  }
  if (dataQuery && !error) {
    return (
      <StyledMainRow
        paddingTop={40}
        lgPaddingTop={48}
        smPaddingTop={40}
        spaceBetween
        nowrap
      >
        <StyledCol width={'780px'} gap={12}>
          <Text>
            Основной задачей Предквалификации является формирование Реестра
            потенциальных участников закупок, способных выполнять определенные
            виды работ, оказывать определенные виды услуг, осуществлять поставку
            определенных товаров в соответствии с установленными требованиями к
            производственным процессам, качеству и безопасности товаров,
            результатов работ и услуг, для последующего приглашения к участию в
            объявляемых закупках потенциальных участников, квалификация которых
            соответствует требуемому по соответствующему предмету закупки
            уровню.
          </Text>

          <Text paddingTop={'16px'}>
            При проведении закупок Организатор вправе разрешить лицам,
            включенным в Реестр потенциальных участников закупок, не
            представлять отдельные документы, представленные ими раннее для
            прохождения Предквалификации, за исключением документов, в которые
            были внесены изменения, и документов, утративших силу на момент
            подачи заявки на участие в закупке.
          </Text>

          <Text paddingTop={'16px'}>
            Результатом проведенной Предквалификации является решение о
            включении или об отказе во включении участника Предквалификации в
            Реестр потенциальных участников закупок по определенным видам
            поставляемых товаров, выполняемых работ, оказываемых услуг,
            размещенный на официальном Интернет-сайте.
          </Text>

          {dataQuery.attributes.documents?.data.length !== 0 && (
            <Container hidden lgVisible lgPaddingTop={48} smPaddingTop={32}>
              <Subtitle paddingTop={'unset'} document>
                Документы
              </Subtitle>

              <StyledDocumentRow>
                {dataQuery.attributes.documents?.data.map((item, index) => (
                  <File
                    title={item.attributes.name}
                    ext={item.attributes.ext}
                    size={item.attributes.size}
                    url={item.attributes.url}
                    key={index}
                  />
                ))}
              </StyledDocumentRow>
            </Container>
          )}

          <BoldText>Почта для подачи заявок на предквалификацию</BoldText>

          <TextLink href='mailto:info@rador.ru' paddingTop={'20px'}>
            info@rador.ru
          </TextLink>
        </StyledCol>

        {dataQuery.attributes.documents?.data.length !== 0 && (
          <StyledCol width={'280px'} lgHidden>
            <Subtitle paddingTop={'unset'} document>
              Документы
            </Subtitle>

            <StyledDocumentRow>
              {dataQuery.attributes.documents?.data.map((item, index) => (
                <File
                  title={item.attributes.name}
                  ext={item.attributes.ext}
                  size={item.attributes.size}
                  url={item.attributes.url}
                  key={index}
                />
              ))}
            </StyledDocumentRow>
          </StyledCol>
        )}
      </StyledMainRow>
    );
  }
};

const Text = styled.p`
  padding-top: ${(p) => (p.paddingTop ? p.paddingTop : '')};
  ${txtXm};
  @media (max-width: ${BREAKPOINTS.lg}) {
    ${(p) => (p.lgPaddingTop ? `padding-top: ${p.lgPaddingTop}px` : '')};
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    ${(p) => (p.smPaddingTop ? `padding-top: ${p.smPaddingTop}px` : '')};
    ${txtSm};
    font-weight: 400;
  }
`;

const BoldText = styled.p`
  padding-top: 60px;
  ${h5h};
  ${latoFn};
  @media (max-width: ${BREAKPOINTS.lg}) {
    padding-top: 64px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    padding-top: 48px;
    ${txtMd};
    ${latoFn};
    font-weight: 700;
  }
`;

const StyledCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.lg}) {
    max-width: unset;
  }
`;

const Subtitle = styled.p`
  padding-top: ${(p) => (p.paddingTop ? p.paddingTop : '60px')};
  ${h5h};
  margin-bottom: ${(p) => (p.document ? '24px' : '')};
  @media (max-width: ${BREAKPOINTS.lg}) {
    padding-top: ${(p) => (p.lgPaddingTop ? `${p.lgPaddingTop}px` : '')};
    margin-bottom: ${(p) => (p.document ? '16px' : '')};
  }
  @media (max-width: ${BREAKPOINTS.lg}) {
    padding-top: ${(p) => (p.smPaddingTop ? `${p.smPaddingTop}px` : '')};
  }
`;

const StyledDocumentRow = styled(Row)`
  flex-direction: column;
  gap: 16px;
  @media (max-width: ${BREAKPOINTS.lg}) {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const StyledMainRow = styled(Row)`
  gap: 40px;
  @media (max-width: ${BREAKPOINTS.lg}) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 48px;
  }
`;

const TextLink = styled.a`
  padding-top: ${(p) => (p.paddingTop ? p.paddingTop : '')};
  ${txtXm};
  @media (max-width: ${BREAKPOINTS.lg}) {
    ${(p) => (p.lgPaddingTop ? `padding-top: ${p.lgPaddingTop}px` : '')};
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    ${(p) => (p.smPaddingTop ? `padding-top: ${p.smPaddingTop}px` : '')};
    ${txtSm};
    font-weight: 400;
  }
  :hover {
    cursor: pointer;
    color: ${hover};
  }
`;
