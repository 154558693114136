import styled from 'styled-components';
import { white } from 'Design';
import { BREAKPOINTS } from 'utils';

export const ProgressBar = ({ length, active, onClick }) => {
  return (
    <>
      <Bar
        length={length >= 1}
        active={active >= 1 ? active : null}
        onClick={() => onClick(0)}
      />
      <Bar
        length={length >= 2}
        active={active >= 2 ? active : null}
        onClick={() => onClick(1)}
      />
      <Bar
        length={length >= 3}
        active={active >= 3 ? active : null}
        onClick={() => onClick(2)}
      />
      <Bar
        length={length >= 4}
        active={active >= 4 ? active : null}
        onClick={() => onClick(3)}
      />
      <Bar
        length={length >= 5}
        active={active >= 5 ? active : null}
        onClick={() => onClick(4)}
      />
      <Bar
        length={length >= 6}
        active={active >= 6 ? active : null}
        onClick={() => onClick(5)}
      />
      <Bar
        length={length >= 7}
        active={active >= 7 ? active : null}
        onClick={() => onClick(6)}
      />
      <Bar
        length={length >= 8}
        active={active >= 8 ? active : null}
        onClick={() => onClick(7)}
      />
    </>
  );
};

const Bar = styled.div`
  margin-right: 8px;
  width: 50px;
  height: 4px;
  display: ${(props) => (props.length ? 'inline-block' : 'none')};
  cursor: pointer;
  background: rgba(217, 217, 217, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 100px;
  ${(p) =>
    p.active
      ? `background: ${white};
         backdrop-filter: blur(10px);`
      : ''}
  @media (max-width: ${BREAKPOINTS.sm}) {
    width: 20px;
  }
  @media (max-width: ${BREAKPOINTS.xs}) {
    width: 20px;
  }
`;
