import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Container,
  h5h,
  hover,
  latoFn,
  openFn,
  passed,
  tabHeader
} from 'Design';
import { BackToPage } from 'components';
import { black } from 'Design';
import { Row } from 'Design';
import { Active } from './sections/Active';
import { Qualification } from './sections/Qualification';
import { Archive } from './sections/Archive';
import { BREAKPOINTS } from 'utils';

export const PurchasesPage = () => {
  const [activeTab, setActiveTab] = useState('active');

  const onPressTab = (tab) => {
    switch (tab) {
      case 'active':
        setActiveTab('active');
        break;
      case 'archive':
        setActiveTab('archive');
        break;
      case 'qualification':
        setActiveTab('qualification');
        break;
      default:
        break;
    }
  };
  return (
    <Wrapper
      maxWidth={1180}
      paddingTop={116}
      paddingX={32}
      xlPaddingTop={112}
      mdPaddingX={16}
      smPaddingTop={96}
    >
      <BackToPage text={'Назад на главную'} link={'/'} />

      <Container paddingTop={40}>
        <Title>Закупки</Title>

        <StyledRow paddingTop={30} lgPaddingTop={32} nowrap>
          <StyledTab
            active={activeTab === 'active'}
            onClick={() => onPressTab('active')}
          >
            Действующие закупки
          </StyledTab>
          <StyledTab
            active={activeTab === 'qualification'}
            onClick={() => onPressTab('qualification')}
          >
            Предквалификация
          </StyledTab>
          <StyledTab
            active={activeTab === 'archive'}
            onClick={() => onPressTab('archive')}
          >
            Архив закупок
          </StyledTab>
        </StyledRow>

        {activeTab === 'active' && <Active />}

        {activeTab === 'qualification' && <Qualification />}

        {activeTab === 'archive' && <Archive />}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const Title = styled.p`
  ${latoFn}
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: ${black};
  @media (max-width: ${BREAKPOINTS.sm}) {
    ${h5h}
  }
`;

const StyledTab = styled.button`
  padding-bottom: 4px;
  background: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  ${(p) =>
    p.active
      ? `
    ${latoFn}
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${hover};
  border-bottom: 1px solid ${hover};
    `
      : `
      ${openFn};
    ${tabHeader};
    color: ${passed};
    border-bottom: 1px solid transparent;
    `};
  @media (max-width: ${BREAKPOINTS.sm}) {
    ${(p) =>
      p.active
        ? `
          font-size: 16px;
          line-height: 19px;
        `
        : `
        font-size: 16px;
        line-height: 22px;`}
  }
`;

const StyledRow = styled(Row)`
  gap: 30px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledFilterRow = styled(Row)`
  gap: 40px;
`;
