import { useQuery } from '@apollo/client';
import { GET_ARTICLE } from 'api/queries';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BREAKPOINTS, getImageUrl } from 'utils';
import shadow from 'images/Article/shadow.png';
import { Container, h1h, h5h, passed, Row, txtLg } from 'Design';
import { BackToPage, Date, Loader, OtherNews } from 'components';
import ReactMarkdown from 'react-markdown';

export const ArticlePage = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_ARTICLE, {
    variables: { id: id }
  });

  const dataQuery = useMemo(() => {
    if (data) {
      return data.article?.data;
    }

    return null;
  }, [data]);

  if (loading) {
    return (
      <Row justifyContent={'center'} wide alignCenter height={'600px'}>
        <Loader />
      </Row>
    );
  }

  const imgUrl = getImageUrl(dataQuery.attributes.img?.data?.attributes?.url);

  if (dataQuery && !error) {
    return (
      <>
        <BG bg={imgUrl}>
          <Shadow>
            <Wrapper
              maxWidth={1180}
              paddingTop={40}
              paddingX={130}
              xlPaddingX={32}
              mdPaddingX={16}
              mdPaddingTop={30}
            >
              <BackToPage text={'Назад ко всем новостям'} link='/news' />
              <StyledRow
                mdMarginTop={'-66'}
                justifyContent={'center'}
                alignCenter
                width={'100%'}
                height={'720px'}
                direction={'column'}
              >
                <Title>{dataQuery.attributes.title}</Title>
              </StyledRow>
            </Wrapper>
          </Shadow>
        </BG>

        <Wrapper
          maxWidth={1180}
          paddingTop={36}
          paddingX={130}
          xlPaddingX={32}
          xlPaddingTop={48}
          mdPaddingX={16}
          mdPaddingTop={32}
        >
          <Container marginBottom={12}>
            <Date date={dataQuery.attributes.date} />
          </Container>
          <Row spaceBetween>
            <ContainerDesktop maxWidth={680} xlMarginRight={1}>
              {dataQuery.attributes.description && (
                <Description>{dataQuery.attributes.description}</Description>
              )}

              <Text linkTarget='_blank'>{dataQuery.attributes.text}</Text>
            </ContainerDesktop>
            <ContainerDesktop xxlHidden>
              <OtherNews />
            </ContainerDesktop>
          </Row>
          <ContainerMobile hidden xxlVisible marginTop={60}>
            <OtherNews />
          </ContainerMobile>
        </Wrapper>
      </>
    );
  }
  return <p>Something went wrong...</p>;
};

const ContainerDesktop = styled(Container)`
  @media (max-width: ${BREAKPOINTS.xxl}) {
    max-width: unset;
  }
`;

const ContainerMobile = styled(Container)``;

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const BG = styled.div`
  width: 100%;
  height: 720px;
  ${(p) => (p.bg ? `background-image: url(${p.bg});` : '')}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 384px;
  }
`;

const Shadow = styled(BG)`
  height: 721px;
  background-image: url(${shadow});
  @media (max-width: ${BREAKPOINTS.md}) {
    background-position: center;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 385px;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 384px;
  }
`;

const Title = styled.h3`
  ${h1h};
  text-align: center;
  max-width: 780px;
  @media (max-width: ${BREAKPOINTS.xl}) {
    font-size: 40px;
    line-height: 48px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const Description = styled.p`
  margin-bottom: 24px;
  ${h5h}
`;

const Text = styled(ReactMarkdown)`
  & > * {
    margin-bottom: 16px;
    ${txtLg}
  }
  & p a {
    color: ${passed};
  }
  & :last-child {
    margin-bottom: unset;
  }
  @media (max-width: ${BREAKPOINTS.xl}) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 18px;
    line-height: 25px;
  }
`;
