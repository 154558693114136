import React from 'react';
import { Route, Routes as Switch, Navigate } from 'react-router-dom';
import {
  MainPage,
  ContactsPage,
  ArticlePage,
  AboutUsPage,
  JobsPage,
  NewsPage,
  TeamPage,
  VacancyPage,
  PurchasesPage,
  OnePurchasePage
} from 'pages';

export const Routes = () => {
  return (
    <Switch>
      <Route path='/' element={<MainPage />} />
      <Route path='/contacts' element={<ContactsPage />} />
      <Route path='/about-us' element={<AboutUsPage />} />
      <Route path='/purchases' element={<PurchasesPage />} />
      <Route path='/purchase/:id' element={<OnePurchasePage />} />
      {/* <Route path='/team' element={<TeamPage />} /> */}
      <Route path='/news' element={<NewsPage />} />
      <Route path='/news/:id' element={<ArticlePage />} />
      <Route path='/jobs' element={<JobsPage />} />
      <Route path='/jobs/:id' element={<VacancyPage />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Switch>
  );
};
