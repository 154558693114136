import React from 'react';
import styled from 'styled-components';
import { txtMd } from 'Design';
import { BREAKPOINTS } from 'utils';
import { Arrow } from 'components/icons';
import { Link } from 'react-router-dom';

export const VacancyButton = ({ title, id, disabled = false }) => {
  return (
    <Button disabled={disabled} to={`/jobs/${id}`}>
      <span>{title}</span>
      <Arrow />
    </Button>
  );
};

const Button = styled(Link)`
  padding: 16px 24px;
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: unset;
  border-radius: 20px;
  background: #f9f9f9;
  border-radius: 20px;
  cursor: pointer;
  & span {
    ${txtMd}
    padding-right: 20px;
  }

  &:hover {
    background: #e0ebff;
  }
  &:active {
    background: #bbd3ff;
  }
  &:disabled {
    opacity: 0.4;
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    padding: 0 12px;
    height: 56px;
    border-radius: 12px;
    & span {
      font-size: 16px;
      line-height: 19px;
    }
    & div {
      width: 24px;
      height: 24px;
    }
  }
`;
