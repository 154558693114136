import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';
import { white } from '../../../Design';
import cancel_icon from '../../../images/icons/cancel.svg';
import email_icon from '../../../images/icons/email.svg';
import location_icon from '../../../images/icons/location.svg';
import menu_icon from '../../../images/icons/menu.svg';
import minus_icon from '../../../images/icons/minus.svg';
import phone_icon from '../../../images/icons/phone.svg';

const getIcon = (type) => {
  switch (type) {
    case 'cancel':
      return cancel_icon;
    case 'email':
      return email_icon;
    case 'location':
      return location_icon;
    case 'menu':
      return menu_icon;
    case 'minus':
      return minus_icon;
    case 'phone':
      return phone_icon;
    default:
      return cancel_icon;
  }
};

export const Icon = ({
  type = '',
  left = false,
  small = false,
  large = false,
  noFill = false
}) => {
  return (
    <Wr type={type} left={left} small={small} large={large} noFill={noFill} />
  );
};

const Wr = styled.div`
  width: ${(p) => (p.large ? '40px' : p.small ? '24px' : '36px')};
  height: ${(p) => (p.large ? '40px' : p.small ? '24px' : '36px')};
  background-color: ${(p) => (p.noFill ? 'none' : white)};
  background-image: url(${(p) => getIcon(p.type)});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: ${(p) => p.left && 'scale(-1, 1)'};
  @media (max-width: ${BREAKPOINTS.sm}) {
    width: 24px;
    height: 24px;
  }
`;
