import React from 'react';
import styled from 'styled-components';
import { Container, h3h, Img, Row, white } from 'Design';
import { BackToPage, FullName, Position } from 'components';
import { BREAKPOINTS } from 'utils';
import bg from 'images/Team/bg.jpg';
import shadow from 'images/Team/shadow.png';
import ill1 from 'images/Team/image1.png';
import ill2 from 'images/Team/image2.png';
import ill3 from 'images/Team/image3.png';
import ill4 from 'images/Team/image4.png';
import ill5 from 'images/Team/image5.png';
import ill6 from 'images/Team/image6.png';
import ill7 from 'images/Team/image7.png';

const manadgement_department = [
  {
    name: 'Владислав Потоцкий',
    position: 'Генеральный директор',
    img: ill1
  },
  {
    name: 'Ворожейкин Владимир Владимирович',
    position: 'Заместитель генерального директора по общим вопросам',
    img: ill7
  },
  {
    name: 'Александр Головацкий',
    position: 'Заместитель генерального директора по строительству',
    img: ill2
  },
  {
    name: `Дмитрий Соколов`,
    position:
      'Заместитель генерального директора по земельным и имущественным отношениям',
    img: ill3
  },
  {
    name: 'Николай Фурсевич',
    position: 'Технический директор',
    img: ill5
  },
  {
    name: 'Евгений Шимохин',
    position: 'Главный инженер',
    img: ill6
  }
];

const ItemPerson = ({ name, position, img, big }) => {
  return (
    <ManagerContainer big={big} maxWidth={big ? 280 : 260}>
      <PersonImg src={img} />
      <Container marginBottom={12} marginTop={big ? 24 : 20}>
        <FullName text={name} big={big} />
      </Container>
      <StyledContainer maxWidth={251}>
        <Position text={position} />
      </StyledContainer>
    </ManagerContainer>
  );
};

export const TeamPage = () => {
  return (
    <>
      <BG>
        <Shadow>
          <Wrapper
            maxWidth={1180}
            paddingX={32}
            paddingTop={116}
            xlPaddingTop={112}
            smPaddingTop={96}
            smPaddingX={16}
          >
            <BackToPage text={'Назад на главную'} link={'/'} light />
            <StyledRow
              marginTop={'-115'}
              mdMarginTop={'-120'}
              smMarginTop={'-90'}
              justifyContent={'center'}
              alignCenter
              width={'100%'}
              height={'586px'}
              direction={'column'}
            >
              <MainDescription>
                Мы – команда высококлассных специалистов различных направлений
                дорожного строительства
              </MainDescription>
            </StyledRow>
          </Wrapper>
        </Shadow>
      </BG>
      <Wrapper
        maxWidth={1180}
        paddingX={32}
        paddingTop={120}
        lgPaddingTop={48}
        smPaddingTop={38}
        smPaddingX={16}
        paddingBottom={60}
      >
        <Row
          justifyContent={'center'}
          marginBottom={80}
          lgMarginBottom={60}
          smMarginBottom={48}
        >
          <ItemPerson
            big
            name={manadgement_department[0].name}
            position={manadgement_department[0].position}
            img={manadgement_department[0].img}
          />
        </Row>

        <ManagersRow justifyContent={'center'}>
          {manadgement_department.slice(1).map((item, index) => (
            <ItemPerson
              key={index}
              name={item.name}
              position={item.position}
              img={item.img}
            />
          ))}
        </ManagersRow>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const BG = styled.div`
  width: 100%;
  height: 586px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 384px;
  }
`;

const Shadow = styled(BG)`
  height: 586px;
  /* background-image: url(${shadow}); */
  background: linear-gradient(
    94.12deg,
    rgba(19, 19, 19, 0.48) 56.81%,
    rgba(19, 19, 19, 0) 106.21%
  );
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 385px;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    align-items: flex-start;
    height: 384px;
  }
`;

const MainDescription = styled.p`
  max-width: 768px;
  ${h3h}
  text-align: center;
  color: ${white};
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 30px;
    line-height: 36px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const ManagersRow = styled(Row)`
  display: grid;
  grid-template-columns: repeat(3, 260px);
  justify-items: center;
  column-gap: 40px;
  row-gap: 80px;
  @media (max-width: ${BREAKPOINTS.lg}) {
    display: flex;
    grid-template-columns: repeat(3, 220px);
    column-gap: 22px;
    row-gap: 60px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    flex-direction: column;
    align-items: center;
    column-gap: unset;
    row-gap: 36px;
  }
`;

const ManagerContainer = styled(Container)`
  width: 100%;
  @media (max-width: ${BREAKPOINTS.lg}) {
    max-width: ${(p) => (p.big ? '240px' : '220px')};
  }
`;

const PersonImg = styled(Img)`
  width: 100%;
`;

const StyledContainer = styled(Container)`
  margin: 0 auto;
`;
