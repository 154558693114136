import React from 'react';
import styled from 'styled-components';
import { hover, passed, tabHeader } from 'Design';

const Tab = ({ text, disabled = false }) => {
  return <StyledTab disabled={disabled}>{text}</StyledTab>;
};

const StyledTab = styled.button`
  ${tabHeader}
  cursor: pointer;
  background-color: unset;
  border: unset;
  ${(p) =>
    p.disabled
      ? `cursor:default;
         opacity: .4`
      : ''}
  &:hover {
    ${(p) => (!p.disabled ? `color: ${hover};` : '')}
  }

  &:active {
    ${(p) => (!p.disabled ? `color: ${passed};` : '')}
  }
`;

export default Tab;
