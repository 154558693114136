import { h5h, headLg, headMd } from 'Design';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';

export const FullName = ({ text, big }) => {
  return <Text big={big}>{text}</Text>;
};

const Text = styled.p`
  text-align: center;
  ${(p) => (p.big ? h5h : headLg)};
  white-space: pre-wrap;
  @media (max-width: ${BREAKPOINTS.lg}) {
    ${(p) => (p.big ? headLg : headMd)};
  }
`;
