import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';
import './SelectCategory.css';
import { ReactComponent as ArrowDown } from 'images/icons/arrow_down.svg';
export const SelectCategory = ({ handleCategory, category }) => {
  return (
    <CustomSelect
      allowClear={true}
      placeholder='Категория'
      onChange={(value) => handleCategory(value)}
      value={category}
      suffixIcon={<ArrowDown />}
      options={[
        {
          value: 'uslugi_podryada_na_SMR',
          label: 'Услуги подряда на СМР'
        },
        {
          value: 'uslugi',
          label: 'Услуги'
        },
        {
          value: 'postavka_materialov',
          label: 'Поставка материалов'
        }
      ]}
    />
  );
};

const CustomSelect = styled(Select)`
  max-width: 360px;
  width: 100%;
  /* & .ant-select-selector {
    padding: 18px 12px;
  } */
  .ant-select-clear {
    width: 24px !important;
    height: 24px !important;
  }
  @media (max-width: ${BREAKPOINTS.lg}) {
    max-width: 272px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    max-width: unset;
  }
`;
