import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { client } from './api/ApolloClient';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { API_KEY } from './utils';
import { YMaps } from '@pbe/react-yandex-maps';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <YMaps
      query={{
        lang: 'ru_RU',
        apikey: API_KEY
      }}
    >
      <ApolloProvider client={client}>
        <Router>
          <App />
        </Router>
      </ApolloProvider>
    </YMaps>
  </React.StrictMode>
);
