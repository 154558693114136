import React from 'react';
import styled from 'styled-components';
import { Container, Img, Row, hover, txtSm, txtXm } from 'Design';
import icon from 'images/icons/document.svg';
import { API_URL, BREAKPOINTS } from 'utils';

export const File = ({ title, ext, size, url }) => {
  const filesSize =
    size > 1000 ? `${Math.floor(size / 1000)} мб` : `${Math.floor(size)} кб`;

  const onDownload = async () => {
    try {
      const res = await fetch(`${API_URL}${url}`);
      const blob = await res.blob();

      const a = document.createElement('a');
      const href = window.URL.createObjectURL(blob);
      a.href = href;
      a.setAttribute('download', title);
      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(href);
      a.remove();
    } catch (e) {
      alert('Something went wrong');
      window.open(`${API_URL}${url}`);
      console.error(e);
    }
  };
  return (
    <StyledMainRow alignItems={'flex-start'} nowrap onClick={onDownload}>
      <StyledImg src={icon} />
      <Container>
        <Title>{title}</Title>
        <Text>
          {ext} {filesSize}
        </Text>
      </Container>
    </StyledMainRow>
  );
};

const StyledMainRow = styled(Row)`
  gap: 10px;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.sm}) {
    gap: 16px;
  }
`;

const Title = styled.p`
  margin-bottom: 4px;
  ${txtXm};
  color: ${hover};
  @media (max-width: ${BREAKPOINTS.sm}) {
    margin-bottom: 8px;
    ${txtSm};
    font-weight: 400;
  }
`;

const Text = styled.p`
  ${txtSm};
  font-weight: 400;
  color: #9f9f9f;
`;

const StyledImg = styled(Img)`
  width: 40px;
  height: 40px;
  @media (max-width: ${BREAKPOINTS.sm}) {
    width: 36px;
    height: 36px;
  }
`;
