import React from 'react';
import { Container, h1h, h2h, h3h, h5h, Row, white } from 'Design';
import styled from 'styled-components';
import bg from 'images/AboutUs/bg.jpg';
import shadow from 'images/AboutUs/shadow.png';

import { BackToPage } from 'components';
import { BREAKPOINTS } from 'utils';

export const AboutCompany = (props) => {
  return (
    <>
      <Container>
        <BG>
          <Shadow>
            <Wrapper {...props} maxWidth={1180}>
              <BackToPage text='Назад на главную' link='/' light />

              <StyledRow
                marginTop={'-115'}
                mdMarginTop={'-120'}
                smMarginTop={'-90'}
                justifyContent={'center'}
                alignCenter
                width={'100%'}
                height={'586px'}
                direction={'column'}
              >
                <Description>
                  Русские автомобильные
                  <br /> дороги – это качественные
                  <br /> дороги на века!
                </Description>

                {/* <Title>О компании</Title> */}
              </StyledRow>
            </Wrapper>
          </Shadow>
        </BG>
      </Container>
    </>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const BG = styled.div`
  width: 100%;
  height: 586px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 384px;
  }
`;

const Shadow = styled(BG)`
  height: 586px;
  /* background-image: url(${shadow}); */
  background: linear-gradient(
    94.12deg,
    rgba(19, 19, 19, 0.48) 56.81%,
    rgba(19, 19, 19, 0) 106.21%
  );
  @media (max-width: ${BREAKPOINTS.md}) {
    background-position: top left;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 385px;
  }
`;

const Title = styled.span`
  ${h5h};
  background: linear-gradient(272.25deg, #2e61e3 2.99%, #30cae2 96.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const Description = styled.p`
  text-align: center;
  ${h3h};
  color: ${white};
  @media (max-width: ${BREAKPOINTS.xl}) {
    ${h2h}
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    text-align: start;
    font-size: 24px;
    line-height: 29px;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 384px;
    align-items: flex-start;
  }
`;
