import { useQuery } from '@apollo/client';
import { GET_LAST_THREE_ARTICLES } from 'api/queries';
import { Arrow, Loader } from 'components';
import { Container, h3h, h5h, hover, passed, Row, txtLg } from 'Design';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';
import { Preview } from './Preview';

export const News = (props) => {
  const { loading, data, error } = useQuery(GET_LAST_THREE_ARTICLES);

  if (loading) {
    return (
      <Row justifyContent={'center'} wide alignCenter height={'600px'}>
        <Loader />
      </Row>
    );
  } else if (error) {
    return <p>Something went wrong...</p>;
  }

  const articles = data.articles?.data;

  return (
    <Wrapper {...props} maxWidth={1180}>
      <Row spaceBetween alignCenter>
        <div>
          <Title>Новости</Title>
          {/* <Description>Что у нас нового</Description> */}
        </div>
        <Container smHidden>
          <StyledButton to='/news'>
            <span>Все новости</span>
            <Arrow />
          </StyledButton>
        </Container>
      </Row>

      <Container xlHidden marginTop={60}>
        <StyledRow lgMarginTop={40} smMarginTop={30} nowrap>
          {articles.map((item, index) =>
            index === 0 ? (
              <div key={index}>
                <Preview data={item} big />
              </div>
            ) : (
              <div key={index}>
                <Preview data={item} />
              </div>
            )
          )}
        </StyledRow>
      </Container>

      <Container hidden xlVisible smHidden xlMarginTop={40}>
        <StyledRow nowrap justifyContent={'center'}>
          {articles.slice(0, 2).map((item, index) =>
            index === 0 ? (
              <div key={index}>
                <Preview data={item} big />
              </div>
            ) : (
              <div key={index}>
                <Preview data={item} />
              </div>
            )
          )}
        </StyledRow>
      </Container>

      <Container hidden smVisible smMarginTop={30}>
        <StyledRow justifyContent={'center'} direction={'column'}>
          {articles.slice(0, 2).map((item, index) => (
            <div key={index}>
              <Preview data={item} />
            </div>
          ))}
        </StyledRow>
      </Container>

      <Container hidden smVisible marginTop={100}>
        <Row justifyContent={'center'}>
          <StyledButton to='/news'>
            <span>Все новости</span>
            <Arrow small />
          </StyledButton>
        </Row>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const Title = styled.span`
  ${h5h}
  background: linear-gradient(272.25deg, #2E61E3 2.99%, #30CAE2 96.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const Description = styled.h3`
  ${h3h}
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 30px;
    line-height: 36px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const StyledButton = styled(Link)`
  display: flex;
  align-items: center;
  gap: 24px;
  & > span {
    ${txtLg}
    &:hover {
      color: ${hover};
    }
    &:active {
      color: ${passed};
    }
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    & > span {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const StyledRow = styled(Row)`
  gap: 30px;
`;
