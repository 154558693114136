export const API_URL = process.env.REACT_APP_API_URL;
export const API_KEY = process.env.REACT_APP_YANDEX_API_KEY;

export const BREAKPOINTS = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px'
};

export * from './helpers';
