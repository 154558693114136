import { Container, h1h, h2h, h3h, h5h, Row, white } from 'Design';
import React from 'react';
import styled from 'styled-components';
import bg from 'images/Contacts/bg.jpg';
import shadow from 'images/Contacts/shadow.png';
import { BREAKPOINTS } from 'utils';
import { BackToPage } from 'components';

export const Main = (props) => {
  return (
    <BG>
      <Shadow>
        <Wrapper maxWidth={1180} {...props}>
          <BackToPage text='Назад на главную' link='/' light />
          <StyledRow
            marginTop={'-115'}
            mdMarginTop={'-120'}
            smMarginTop={'-90'}
            justifyContent={'center'}
            alignCenter
            width={'100%'}
            height={'586px'}
            direction={'column'}
          >
            <Description>
              Мы открыты к новому сотрудничеству – свяжитесь с нами
            </Description>

            {/* <Title>Контакты</Title> */}
          </StyledRow>
        </Wrapper>
      </Shadow>
    </BG>
  );
};

const BG = styled.div`
  width: 100%;
  height: 586px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 384px;
  }
`;

const Shadow = styled(BG)`
  height: 586px;
  /* background-image: url(${shadow}); */
  background: linear-gradient(
    93.63deg,
    rgba(19, 19, 19, 0.48) 49.67%,
    rgba(19, 19, 19, 0) 85.33%
  );
  @media (max-width: ${BREAKPOINTS.md}) {
    background-position: center;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 385px;
  }
`;
const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 384px;
    align-items: flex-start;
  }
`;

const Title = styled.span`
  ${h5h};
  background: linear-gradient(272.25deg, #2e61e3 2.99%, #30cae2 96.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const Description = styled.p`
  max-width: 726px;
  text-align: center;
  ${h3h}
  text-align: center;
  color: ${white};
  @media (max-width: ${BREAKPOINTS.xl}) {
    /* ${h2h} */
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    text-align: start;
    font-size: 24px;
    line-height: 29px;
  }
`;
