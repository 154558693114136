import { Container, h3h, h5h, positionText, Row } from 'Design';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';
// import ill1 from 'images/Home/Mission/icon1.svg';
// import ill2 from 'images/Home/Mission/icon2.svg';
// import ill3 from 'images/Home/Mission/icon3.svg';
import ill4 from 'images/Home/Mission/number_01.png';
import ill5 from 'images/Home/Mission/number_02.png';
import ill6 from 'images/Home/Mission/number_03.png';
const Block = ({ img, text }) => {
  return (
    <ContainerBlock
      relative
      paddingTop={44}
      lgPaddingTop={40}
      smPaddingTop={1}
      maxWidth={380}
    >
      <Img src={img} />
      <Text>{text}</Text>
    </ContainerBlock>
  );
};

export const Mission = (props) => {
  return (
    <Wrapper {...props} maxWidth={1180}>
      {/* <Title>Миссия компании</Title> */}
      {/* <Description>Наши главные задачи</Description> */}
      <Title marginBig>Наши главные задачи</Title>
      <StyledRow justifyContent={'center'}>
        <Block
          text='Кардинально улучшить качество, долговечность и безопасность дорожной сети России'
          img={ill4}
        />
        <Block
          text='Применять новые комплексные подходы к строительству и эксплуатации транспортных магистралей'
          img={ill5}
        />
        <Block
          text='Использовать новейшие строительные технологии и системы обеспечения безопасности'
          img={ill6}
        />
      </StyledRow>
    </Wrapper>
  );
};

const ContainerBlock = styled(Container)`
  @media (max-width: ${BREAKPOINTS.lg}) {
    max-width: 340px;
  }
`;

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const Title = styled.span`
  display: inline-block;
  margin-bottom: ${(p) => (p.marginBig ? '60px' : '15px')};
  ${h5h}
  background: linear-gradient(272.25deg, #2E61E3 2.99%, #30CAE2 96.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const Description = styled.h3`
  ${h3h}
  margin-bottom: 60px;
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 30px;
    line-height: 36px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 21px;
  }
`;

const StyledRow = styled(Row)`
  gap: 20px;
  row-gap: 30px;
  @media (max-width: ${BREAKPOINTS.sm}) {
    flex-direction: column;
    row-gap: 16px;
  }
`;

const Img = styled.img`
  width: 96px;
  height: 96px;
  position: absolute;
  top: 0;
  z-index: 10;
  @media (max-width: ${BREAKPOINTS.sm}) {
    position: relative;
  }
`;

const Text = styled.p`
  ${positionText}
  z-index: 20;
  position: relative;
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 21px;
  }
`;
