import { useQuery } from '@apollo/client';
import { GET_VACANCY } from 'api/queries';
import { BackToPage, Loader, PrimaryButton } from 'components';
import { Container, h3h, h4h, headMd, Row, txtMd } from 'Design';
import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';

export const VacancyPage = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_VACANCY, {
    variables: { id: id }
  });

  const dataQuery = useMemo(() => {
    if (data) {
      return data.vacancy?.data;
    }

    return null;
  }, [data]);

  if (loading) {
    return (
      <Row justifyContent={'center'} wide alignCenter height={'600px'}>
        <Loader />
      </Row>
    );
  }
  if (dataQuery && !error) {
    return (
      <>
        <Wrapper
          maxWidth={1180}
          paddingY={40}
          paddingX={32}
          xlPaddingY={40}
          mdPaddingX={16}
          mdPaddingY={30}
        >
          <BackToPage text='Назад к списику вакансий' link='/jobs' />
        </Wrapper>
        <BG>
          <Wrapper
            maxWidth={1180}
            paddingY={50}
            paddingX={130}
            xlPaddingX={32}
            mdPaddingX={16}
            mdPaddingY={30}
          >
            <JobPosition>{dataQuery.attributes.title}</JobPosition>

            <Row spaceBetween alignItems={'flex-start'}>
              <Container>
                <StyledRow marginBottom={19}>
                  <span>Требуемый опыт работы:</span>
                  <span>{dataQuery.attributes.experience}</span>
                </StyledRow>

                <StyledRow>
                  <span>Занятость:</span>
                  <span>{dataQuery.attributes.employment}</span>
                </StyledRow>
              </Container>
              <Container mdHidden>
                {dataQuery.attributes?.link && (
                  <a target='_blank' href={dataQuery.attributes?.link}>
                    <PrimaryButton title='Откликнуться' />
                  </a>
                )}
              </Container>
            </Row>

            <Container marginTop={24} hidden mdVisible>
              {dataQuery.attributes?.link && (
                <a target='_blank' href={dataQuery.attributes?.link}>
                  <PrimaryButton title='Откликнуться' />
                </a>
              )}
            </Container>
          </Wrapper>
        </BG>

        <Wrapper
          maxWidth={1180}
          paddingTop={40}
          paddingX={130}
          xlPaddingTop={32}
          xlPaddingX={32}
          mdPaddingX={16}
        >
          <Description>{dataQuery.attributes.descripition}</Description>
          <H4>Обязанности:</H4>
          <Requirements>{dataQuery.attributes.responsibilities}</Requirements>
          <H4>Требования:</H4>
          <Requirements>{dataQuery.attributes.requirements}</Requirements>
          <H4>Мы предлагаем:</H4>
          <Requirements>{dataQuery.attributes.offer}</Requirements>
          <Container marginTop={60} smHidden>
            {dataQuery.attributes?.link && (
              <a target='_blank' href={dataQuery.attributes?.link}>
                <PrimaryButton title='Откликнуться' />
              </a>
            )}
          </Container>
          <Container hidden smVisible marginTop={60}>
            {dataQuery.attributes?.link && (
              <a target='_blank' href={dataQuery.attributes?.link}>
                <PrimaryButton title='Откликнуться' />
              </a>
            )}
          </Container>
        </Wrapper>
      </>
    );
  }

  return <p>Something went wrong...</p>;
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const BG = styled.div`
  background-color: #f3f7ff;
`;

const JobPosition = styled.h3`
  margin-bottom: 32px;
  ${h3h}
  @media (max-width: ${BREAKPOINTS.xl}) {
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 36px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 22px;
    line-height: 26px;
  }
`;

const StyledRow = styled(Row)`
  gap: 8px;
  & span {
    ${headMd}
  }
  & span + span {
    ${txtMd}
  }
  @media (max-width: ${BREAKPOINTS.xl}) {
    & span + span {
      font-size: 18px;
      line-height: 25px;
    }
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    & span {
      font-size: 16px;
      line-height: 19px;
    }
    & span + span {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const TextContent = styled(ReactMarkdown)`
  & > * {
    ${txtMd}
  }
  & :last-child {
    margin-bottom: 0;
  }
  @media (max-width: ${BREAKPOINTS.xl}) {
    & > * {
      font-size: 18px;
      line-height: 25px;
    }
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    & > * {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const Description = styled(TextContent)`
  & > * {
    margin-bottom: 16px;
  }
`;

const H4 = styled.h4`
  margin-top: 20px;
  margin-bottom: 16px;
  ${h4h}
  @media (max-width: ${BREAKPOINTS.xl}) {
    font-size: 24px;
    line-height: 29px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Requirements = styled(TextContent)`
  & ul {
    padding-left: 35px;
    list-style-position: outside;
    list-style-type: '—  ';
    & > li {
      margin-bottom: 16px;
      font-size: 22px;
    }
  }
`;
