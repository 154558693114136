import React from 'react';
import bg from 'images/AboutUs/bg2.png';
import {
  Col,
  Container,
  h3h,
  Row,
  tabFooterLg,
  tabHeader,
  txtLg
} from 'Design';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';

export const System = (props) => {
  return (
    <div>
      <BG>
        <Wrapper {...props} maxWidth={1180}>
          <Title>Система государственно-частного партнерства</Title>
          <Container mdHidden>
            <StyledRow nowrap marginBottom={40} lgMarginBottom={24}>
              <Col>
                <Text>
                  Однако строительство крупных объектов дорожного строительства
                  требует колоссальных финансовых вложений, и за счет только
                  бюджетного финансирования реализовать разом подобные проекты
                  затруднительно.
                </Text>
              </Col>

              <Col>
                <Text>
                  Концессии применяют с целью привлечения инвестиций в экономику
                  России, обеспечения эффективного использования
                  государственного имущества и повышения качества товаров, работ
                  и услуг, предоставляемых потребителям.
                </Text>
              </Col>
            </StyledRow>

            <StyledRow nowrap>
              <Col>
                <Text>
                  Выход - привлечение частных инвестиций в рамках
                  Государственно-частного партнерства, в том числе в рамках
                  концессионных соглашений.
                </Text>
              </Col>

              <Col>
                <Text>
                  Это позволяет увеличивать скорость строительства и улучшать
                  качество дорого в системе ГЧП, улучшать качество дорог и
                  гарантировать их дальнейшее обслуживание.
                </Text>
              </Col>
            </StyledRow>
          </Container>

          <Container hidden mdVisible>
            <StyledRow direction={'column'}>
              <Text>
                Однако строительство крупных объектов дорожного строительства
                требует колоссальных финансовых вложений, и за счет только
                бюджетного финансирования реализовать разом подобные проекты
                затруднительно.
              </Text>
              <Text>
                Выход - привлечение частных инвестиций в рамках
                Государственно-частного партнерства, в том числе в рамках
                концессионных соглашений.
              </Text>
              <Text>
                Концессии применяют с целью привлечения инвестиций в экономику
                России, обеспечения эффективного использования государственного
                имущества и повышения качества товаров, работ и услуг,
                предоставляемых потребителям.
              </Text>
              <Text>
                Это позволяет увеличивать скорость строительства и улучшать
                качество дорого в системе ГЧП, улучшать качество дорог и
                гарантировать их дальнейшее обслуживание.
              </Text>
            </StyledRow>
          </Container>
        </Wrapper>
      </BG>
    </div>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const BG = styled.div`
  width: 100%;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom left;
  @media (max-width: ${BREAKPOINTS.sm}) {
    background-position: bottom 200px;
  }
`;

const StyledRow = styled(Row)`
  gap: 56px;
  @media (max-width: ${BREAKPOINTS.lg}) {
    gap: 20px;
  }
  @media (max-width: ${BREAKPOINTS.md}) {
    gap: 24px;
  }
`;

const Title = styled.h3`
  margin-bottom: 50px;
  ${h3h}
  @media (max-width:${BREAKPOINTS.lg}) {
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 36px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 29px;
  }
`;

const Text = styled.p`
  ${txtLg}
  max-width: 562px;
  @media (max-width: ${BREAKPOINTS.lg}) {
    ${tabHeader}
  }
  @media (max-width: ${BREAKPOINTS.md}) {
    ${tabFooterLg}
    text-transform: unset;
    max-width: unset;
  }
`;
