import { Container, h3h, h4h, h5h, white } from 'Design';
import React from 'react';
import styled from 'styled-components';
import bg from 'images/Home/Future/bg.jpg';
import bgMobile from 'images/Home/Future/bg-mobile.jpg';
import { BREAKPOINTS } from 'utils';

export const Future = (props) => {
  return (
    <Container {...props}>
      <MobileBG></MobileBG>
      <BG>
        <Wrapper maxWidth={1180} paddingX={32} smPaddingX={16}>
          <Container smHidden>
            <Text>
              Мы строим дороги в<br /> будущее!
            </Text>
          </Container>

          <Container hidden smVisible>
            <Text>
              Мы строим
              <br /> дороги в будущее!
            </Text>
          </Container>
        </Wrapper>
      </BG>
    </Container>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: content-box;
`;

const BG = styled.div`
  height: 387px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${BREAKPOINTS.xl}) {
    height: 312px;
    background-position: center;
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 180px;
    background-position: right;
    /* background-position-x: -386px; */
    /* background-image: url(${bgMobile}); */
  }
`;

const MobileBG = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.sm}) {
    /* padding-top: 78px; */
    height: 78px;
    display: block;
    background-color: #1e1e1e;
  }
`;

const Text = styled.p`
  ${h3h};
  color: ${white};
  @media (max-width: ${BREAKPOINTS.xl}) {
    ${h4h};
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    margin-top: -63px;
    ${h5h};
  }
`;
