import React from 'react';
import { CustomDatePicker, PurchasesTable, SelectCategory } from 'components';
import { useState } from 'react';
import styled from 'styled-components';
import { Row } from 'Design';
import { BREAKPOINTS } from 'utils';

export const Archive = () => {
  const [category, setCategory] = useState('Категория');
  const [date, setDate] = useState(null);

  const handleCategory = (el) => {
    setCategory(el);
  };

  return (
    <>
      <StyledRow paddingTop={38} nowrap>
        <SelectCategory handleCategory={handleCategory} category={category} />
        <CustomDatePicker setDate={setDate} />
      </StyledRow>

      <PurchasesTable status='archive' category={category} date={date} />
    </>
  );
};

const StyledRow = styled(Row)`
  gap: 32px;
  @media (max-width: ${BREAKPOINTS.sm}) {
    flex-direction: column;
    gap: 16px;
  }
`;
