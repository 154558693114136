import Colors from './Colors';
import Fonts from './Fonts';

export * from './Colors';
export * from './Fonts';
export * from './Elements'

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <>
    <Fonts/>
    <Colors/>
  </>
);
