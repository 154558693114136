import { Date, Text, Title } from 'components';
import { Container, hover, Img, passed, Row, tabHeader } from 'Design';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BREAKPOINTS, getImageUrl } from 'utils';

export const ArticlePreview = ({ big = false, data }) => {
  const imgUrl = getImageUrl(data.attributes.img?.data?.attributes?.url);
  return (
    <>
      <ContainerDescktop to={`/news/${data.id}`}>
        {big ? (
          <StyledContainer maxWidth={560}>
            <ContainerWrapper
              big
              paddingBottom={24}
              paddingX={16}
              paddingTop={16}
            >
              <Container marginBottom={8}>
                <Date date={data.attributes.date} />
              </Container>
              <Container marginBottom={16}>
                <Title
                  title={
                    data.attributes.title.length < 50
                      ? data.attributes.title
                      : `${data.attributes.title?.substr(0, 50)}...`
                  }
                />
              </Container>
              <Container>
                {data.attributes.description && (
                  <Text
                    text={
                      data.attributes.description.length < 100
                        ? data.attributes.description
                        : `${data.attributes.description?.substr(0, 100)}...`
                    }
                  />
                )}
              </Container>
            </ContainerWrapper>

            <StyledBigImg maxWidth={560} src={imgUrl} />
          </StyledContainer>
        ) : (
          <ContainerWrapper>
            <StyledRow nowrap>
              {/* <ImgWrapper> */}
              <StyledImg
                src={getImageUrl(data.attributes.img?.data?.attributes?.url)}
              />
              {/* </ImgWrapper> */}
              {/* <ContainerWrapper paddingY={12} paddingRight={12}> */}
              <Container paddingY={12} paddingRight={12}>
                <Container marginBottom={8}>
                  <Date date={data.attributes.date} />
                </Container>

                <ContainerTitle maxWidth={340} xxlMaxWidth={'432px'}>
                  <Container marginBottom={16}>
                    <Title
                      small
                      title={
                        data.attributes.title.length < 50
                          ? data.attributes.title
                          : `${data.attributes.title?.substr(0, 50)}...`
                      }
                    />
                  </Container>
                  {data.attributes.description && (
                    <Description>{`${
                      data.attributes.description.length < 80
                        ? data.attributes.description
                        : `${data.attributes.description?.substr(0, 80)}...`
                    }`}</Description>
                  )}
                </ContainerTitle>
              </Container>
              {/* </ContainerWrapper> */}
            </StyledRow>
          </ContainerWrapper>
        )}
      </ContainerDescktop>

      <ContainerMobile to={`/news/${data.id}`}>
        <MobileRow nowrap>
          <StyledImg
            src={getImageUrl(data.attributes.img?.data?.attributes?.url)}
          />
          <Container paddingY={12} paddingX={12}>
            <Container marginBottom={8}>
              <Date date={data.attributes.date} />
            </Container>

            <ContainerTitle>
              <Container marginBottom={16}>
                <Title
                  small
                  title={
                    data.attributes.title.length < 55
                      ? data.attributes.title
                      : `${data.attributes.title?.substr(0, 55)}...`
                  }
                />
              </Container>
              {data.attributes.description && (
                <Description>{`${
                  data.attributes.description < 100
                    ? data.attributes.description
                    : `${data.attributes.description?.substr(0, 100)}...`
                }`}</Description>
              )}
            </ContainerTitle>
          </Container>
        </MobileRow>
      </ContainerMobile>
    </>
  );
};

const StyledContainer = styled(Container)`
  background-color: #f9f9f9;
  border-radius: 20px;
`;

const Description = styled.p`
  ${tabHeader}
  &:hover {
    color: ${hover};
  }
  &:active {
    color: ${passed};
  }
`;

const ContainerDescktop = styled(Link)`
  cursor: pointer;
  &:hover .date,
  &:hover .title,
  &:hover .text,
  &:hover ${Description} {
    color: ${hover};
  }
  @media (max-width: ${BREAKPOINTS.lg}) {
    display: none;
  }
`;

const ContainerWrapper = styled(Container)`
  background-color: #f9f9f9;
  border-radius: 20px;
  /* border-radius: ${(p) => (p.big ? '20px 20px 0 0' : '0 20px 20px 0')}; */
`;

const ContainerMobile = styled(Link)`
  cursor: pointer;
  display: none;
  background: #f9f9f9;
  border-radius: 20px;

  @media (max-width: ${BREAKPOINTS.lg}) {
    display: block;
    &:hover .date,
    &:hover .title,
    &:hover ${Description} {
      color: ${hover};
    }
  }
`;

const StyledBigImg = styled(Img)`
  border-radius: 20px;
  height: 420px;
  object-fit: cover;
`;

const ImgWrapper = styled.div`
  height: 100%;
  /* background-color: white; */
`;

const StyledRow = styled(Row)`
  gap: 20px;
  @media (max-width: ${BREAKPOINTS.sm}) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
`;

const MobileRow = styled(Row)`
  gap: 28px;
  @media (max-width: 694px) {
    flex-direction: column-reverse;
    gap: unset;
  }
`;

const StyledImg = styled(Img)`
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 20px;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.lg}) {
    max-width: 252px;
    min-height: 188px;
  }

  @media (max-width: 694px) {
    margin-top: 20px;
    max-width: unset;
    height: unset;
  }
`;

const ContainerTitle = styled(Container)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    max-width: unset;
  }
`;
