import { Col, Container, Img, Row, txtMd } from 'Design';
import React from 'react';
import styled from 'styled-components';
import ill1 from 'images/AboutUs/ill1.png';
import ill2 from 'images/AboutUs/ill2.png';
import { BREAKPOINTS } from 'utils';

export const Roads = (props) => {
  return (
    <Wrapper {...props} justifyContent='center'>
      <Container mdHidden>
        <StyledRow nowrap marginBottom={80} xlMarginBottom={30}>
          <Text>
            Общество с ограниченной ответственностью «Русские автомобильные
            дороги» создано в 2022 году с амбициозной, но достижимой целью -
            кардинально обновить принципы и подходы к строительству дорог в
            России, создать собственную концептуальную систему быстрого и
            эффективного строительства дорожных магистралей, с последующим их
            системным обслуживанием. Итогом этого процесса будет переход страны
            на качественно новый уровень дорожной сети скоростных автомобильных
            магистралей, при этом - с высоким уровнем безопасности и долгим
            сроком службы дорожного полотна.
          </Text>

          <Text>
            В такой огромной и протяженной на сотни километров в разные стороны
            света стране как Россия, не обойтись без развитой дорожной сети,
            связывающей регионы в единое целое. ООО «РАД» видит в строительстве
            качественных и современных дорог задачу государственного уровня,
            создающей в долгосрочной перспективе будущее России, как
            пространства с быстрым и безопасным перемещением людей, товаров и
            грузов. России, использующей свои огромные расстояние и уникальное
            географическое положение в качестве международных транспортных
            коридоров, связывающий север и юг, запад и восток.
          </Text>
        </StyledRow>

        <StyledRow nowrap>
          <Col width={'562px'}>
            <Img src={ill1} width={'100%'} />
          </Col>
          <Col width={'562px'}>
            <Img src={ill2} width={'100%'} />
          </Col>
        </StyledRow>
      </Container>

      <Container hidden mdVisible>
        <Text>
          Общество с ограниченной ответственностью «Русские автомобильные
          дороги» создано в 2022 году с амбициозной, но достижимой целью -
          кардинально обновить принципы и подходы к строительству дорог в
          России, создать собственную концептуальную систему быстрого и
          эффективного строительства дорожных магистралей, с последующим их
          системным обслуживанием. Итогом этого процесса будет переход страны на
          качественно новый уровень дорожной сети скоростных автомобильных
          магистралей, при этом - с высоким уровнем безопасности и долгим сроком
          службы дорожного полотна.
        </Text>

        <Img src={ill1} width={'100%'} mdMarginBottom={32} />

        <Text>
          В такой огромной и протяженной на сотни километров в разные стороны
          света стране как Россия, не обойтись без развитой дорожной сети,
          связывающей регионы в единое целое. ООО «РАД» видит в строительстве
          качественных и современных дорог задачу государственного уровня,
          создающей в долгосрочной перспективе будущее России, как пространства
          с быстрым и безопасным перемещением людей, товаров и грузов. России,
          использующей свои огромные расстояние и уникальное географическое
          положение в качестве международных транспортных коридоров, связывающий
          север и юг, запад и восток.
        </Text>

        <Img src={ill2} width={'100%'} />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(Row)``;

const StyledRow = styled(Row)`
  gap: 56px;
  @media (max-width: ${BREAKPOINTS.xl}) {
    gap: 20px;
  }
`;

const Text = styled.p`
  max-width: 562px;
  ${txtMd}
  @media (max-width:${BREAKPOINTS.xl}) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: ${BREAKPOINTS.md}) {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 22px;
    max-width: unset;
  }
`;
