import { Icon } from 'components';
import { Container, h4h, Row, txtLg } from 'Design';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';
import { Map } from './Map';
import { memo } from 'react';

export const Maps = (props) => {
  const MemoMap = memo(Map);
  return (
    <>
      <Row {...props} justifyContent={'center'} direction={'column'}>
        <Title>Фактический (почтовый) адрес</Title>
        <StyledRow
          justifyContent='center'
          alignCenter
          nowrap
          gap={8}
          marginTop={24}
          smMarginTop={20}
          marginBottom={60}
          // lgMarginBottom={93}
          smMarginBottom={84}
        >
          <Icon type='location' />
          <Text>
            г. Москва, поселение Московский, Киевское шоссе 22-й километр,
            домовладение 6, строение 1, БЦ «ComCity», А4а, 4 этаж, 108811
          </Text>
        </StyledRow>

        <Title>Юридический адрес</Title>
        <StyledRow
          justifyContent='center'
          alignCenter
          nowrap
          gap={8}
          marginTop={24}
          smMarginTop={20}
          marginBottom={120}
          // lgMarginBottom={93}
          smMarginBottom={84}
        >
          <Icon type='location' />
          <Text>390046, г. Рязань, ул. Маяковского д.1А, офис 406/1</Text>
        </StyledRow>
      </Row>

      <Wrapper>
        <Container smHidden>
          <MemoMap height={'758px'} />
        </Container>

        <Container hidden smVisible>
          <MemoMap height={'560px'} />
        </Container>
      </Wrapper>
    </>
  );
};

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    align-items: flex-start;
  }
`;

const Title = styled.h4`
  text-align: center;
  ${h4h};
  font-size: 24px;
  font-weight: 600;
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const Text = styled.p`
  ${txtLg}
  text-align: center;
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const Wrapper = styled(Container)`
  width: 100%;
`;
