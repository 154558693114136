import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';
import { h3h, white, txtLg, txtSm, headLg, txtXm, h4h } from 'Design';
import { BREAKPOINTS } from 'utils';
import { ProgressBar } from '../ProgressBar';

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    // zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      // zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

const swipeConfidenceThreshold = 10000;

const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const ProjectSlider = ({
  animations = [],
  autoPlay = true,
  interval = 4000,
  title = '',
  body = '',
  link = ''
}) => {
  // const animations = [img1, img2, img3, img4, img5];

  const [images, setImages] = useState([]);

  useEffect(() => {
    const imgArr = [];

    const cacheImages = async (srcArray) =>
      await Promise.all(
        await srcArray.map(
          (e) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = resolve(imgArr.push(e));
              img.onerror = reject;
              img.src = e;
            })
        )
      ).then(setImages(imgArr));

    cacheImages(animations);
  }, [animations]);

  const [[page, direction], setPage] = useState([0, 0]);

  const imageIndex = wrap(0, images.length, page);
  const paginate = useCallback(
    (newDirection) => {
      setPage([page + newDirection, newDirection]);
    },
    [page]
  );

  const handlePaginate = (pg) => {
    setPage([pg, direction]);
  };

  useEffect(() => {
    if (!autoPlay) {
      return;
    }
    const timer = setInterval(() => paginate(1), interval);
    return () => clearInterval(timer);
  }, [autoPlay, paginate, interval]);

  return (
    <Wr>
      <SlideWr>
        <AnimatePresence initial={false} custom={direction}>
          <SlideItem
            as={motion.img}
            key={page}
            style={{ position: 'absolute', width: '100%', objectFit: 'cover' }}
            src={images[imageIndex]}
            custom={direction}
            variants={variants}
            initial='enter'
            animate='center'
            exit='exit'
            transition={{
              x: { type: 'spring', stiffness: 120, damping: 40 },
              opacity: { duration: 0.65 }
            }}
            drag='x'
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          />
        </AnimatePresence>
        <TextBox>
          <BarWr>
            <ProgressBar
              length={images.length}
              active={imageIndex + 1}
              onClick={handlePaginate}
            />
          </BarWr>

          <h1>{title}</h1>
          <p>{body}</p>
          {/* <ButtonMore href={link}>
            <SecondaryButton title='Узнать подробнее' light />
            <Arrow />
          </ButtonMore> */}
        </TextBox>
      </SlideWr>
    </Wr>
  );
};

const Wr = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: 'flex-start';
  height: 460px;
  border-radius: 40px;
  background-color: #363636;
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 472px;
    border-radius: 0px;
  }
`;

const SlideWr = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  &::after {
    content: '';
    position: absolute;
    width: 63.3%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(90deg, #161616 0%, rgba(0, 0, 0, 0) 93.35%);
    @media (max-width: ${BREAKPOINTS.sm}) {
      width: 100%;
    }
  }
`;

const SlideItem = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
`;

const TextBox = styled.div`
  position: absolute;
  z-index: 2;
  top: 269px;
  left: 60px;
  display: flex;
  flex-direction: column;
  align-items: 'flex-start';
  gap: 16px;
  max-width: 640px;
  text-align: 'left';
  @media (max-width: ${BREAKPOINTS.md}) {
    padding-right: 24px;
    left: 24px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    padding-right: 16px;
    top: 150px;
    left: 16px;
  }
  & h1 {
    ${h4h};
    color: ${white};
    @media (max-width: ${BREAKPOINTS.sm}) {
      ${headLg};
    }
  }
  & p {
    ${txtXm};
    color: ${white};
    @media (max-width: ${BREAKPOINTS.sm}) {
      ${txtSm};
    }
  }
`;
const BarWr = styled.div`
  min-width: 480px;
  display: block;
  position: absolute;
  z-index: 10;
  top: -230px;
  left: 0;
  @media (max-width: ${BREAKPOINTS.sm}) {
    min-width: 310px;
    top: -115px;
  }
  @media (max-width: ${BREAKPOINTS.xs}) {
    min-width: 290px;
  }
`;

const ButtonMore = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 42px;
  gap: 24px;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
  @media (max-width: ${BREAKPOINTS.md}) {
    margin-top: 36px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    margin-top: 24px;
    gap: 12px;
  }
`;
