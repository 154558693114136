import { css, createGlobalStyle } from 'styled-components';

export const latoFn = css`
  font-family: 'Lato', sans-serif;
`;

export const openFn = css`
  font-family: 'Open Sans', sans-serif;
`;

export const [regular, semibold, bold] = ['regular', 'semibold', 'bold'].map(
  (weight) => css`
    font-weight: var(--${weight});
  `
);

export const italic = css`
  font-style: italic;
`;

export const normal = css`
  font-style: normal;
`;

export default createGlobalStyle`
${css`
  :root {
    --regular: 400;
    --semibold: 600;
    --bold: 700;
  }
`}
`;

// FONT STYLES from Figma

const openNormalSemi = css`
  ${openFn};
  ${normal};
  ${semibold};
`;
const openNormalReg = css`
  ${openFn};
  ${normal};
  ${regular};
`;

const latoNormalBold = css`
  ${latoFn};
  ${normal};
  ${bold};
`;

export const btnPimSm = css`
  ${openNormalSemi};
  font-size: 16px;
  line-height: 22px;
`;
export const btnPimLg = css`
  ${openNormalSemi};
  font-size: 18px;
  line-height: 26px;
`;
export const btnSecSm = css`
  ${openNormalSemi};
  font-size: 14px;
  line-height: 20px;
`;
export const btnSecMd = css`
  ${btnPimSm};
`;
export const btnSecLg = css`
  ${openNormalSemi};
  font-size: 20px;
  line-height: 28px;
`;
export const tabHeader = css`
  ${openNormalReg};
  font-size: 18px;
  line-height: 25px;
`;
export const tabFooterSm = css`
  ${openNormalReg};
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
`;
export const tabFooterLg = css`
  ${openNormalReg};
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
`;
export const txtXs = css`
  ${btnSecSm};
`;
export const txtSm = css`
  ${btnPimSm};
`;
export const txtXm = css`
  ${tabHeader};
`;
export const txtMd = css`
  ${openNormalReg};
  font-size: 20px;
  line-height: 27px;
`;
export const txtLg = css`
  ${openNormalReg};
  font-size: 20px;
  line-height: 27px;
`;
export const headXs = css`
  ${latoNormalBold};
  font-size: 14px;
  line-height: 17px;
`;
export const headSm = css`
  ${latoNormalBold};
  font-size: 16px;
  line-height: 19px;
`;
export const headMd = css`
  ${latoNormalBold};
  font-size: 20px;
  line-height: 24px;
`;
export const headLg = css`
  ${latoNormalBold};
  font-size: 22px;
  line-height: 26px;
`;
export const h5h = css`
  ${latoNormalBold};
  font-size: 24px;
  line-height: 29px;
`;
export const h4h = css`
  ${latoNormalBold};
  font-size: 30px;
  line-height: 36px;
`;
export const h3h = css`
  ${latoNormalBold};
  font-size: 36px;
  line-height: 43px;
`;
export const h2h = css`
  ${latoNormalBold};
  font-size: 40px;
  line-height: 48px;
`;
export const h1h = css`
  ${latoNormalBold};
  font-size: 48px;
  line-height: 58px;
`;

export const positionText = css`
  ${openNormalReg}
  font-size: 24px;
  line-height: 33px;
`;

export const descrJobText = css`
  ${openNormalReg}
  font-size: 20px;
  line-height: 27px;
`;
