import React from 'react';
import { AboutCompany, Roads, System } from './section';

export const AboutUsPage = () => {
  return (
    <>
      <AboutCompany
        paddingX={32}
        paddingTop={116}
        xlPaddingTop={112}
        smPaddingTop={96}
        smPaddingX={16}
      />
      <Roads
        paddingX={130}
        paddingY={80}
        xlPaddingX={32}
        xlPaddingTop={54}
        mdPaddingTop={60}
        mdPaddingX={16}
      />
      <System
        paddingX={130}
        paddingTop={44}
        paddingBottom={370}
        xlPaddingX={32}
        xlPaddingTop={60}
        xlPaddingBottom={285}
        smPaddingTop={60}
        smPaddingX={16}
        smPaddingBottom={294}
      />
    </>
  );
};
