import { css, createGlobalStyle } from 'styled-components';

export const [white, black, primary, primaryLt, hover, buttonPass, passed] = [
  'var(--white)',
  'var(--black)',
  'var(--primary)',
  'var(--primaryLt)',
  'var(--hover)',
  'var(--buttonPass)',
  'var(--passed)'
];

export const BUTTON_BG = css`
  background: linear-gradient(87.89deg, #30c6e2 0%, #2e61e3 99.3%);
`;

export const BUTTON_HOVER = css`
  &:hover {
    background: #2e61e3;
    // box-shadow: 0px 6px 20px rgba(48, 190, 226, 0.25);
    // border-radius: 8px;
    // transition: border-radius 0.3s linear;
  }
  &:active {
    background: ${buttonPass};
    // border-radius: 100px;
    // transition: all 0.05s linear;
  }
`;
export const SEC_BUTTON_HOVER = css`
  &:hover {
    background: #2e61e3;
  }
  &:active {
    background: ${buttonPass};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
`;

const hashes = {
  white: '#ffffff',
  black: '#000000',
  primary: '#131313',
  primaryLt: '#F4F4F4',
  hover: '#2E61E3',
  buttonPass: '#0343C7',
  passed: '#0343C7'
};

export default createGlobalStyle`
  ${css`
    :root {
      ${Object.entries(hashes)
        .map(([key, value]) => `--${key}: ${value};`)
        .join(' ')}
    }
  `}
`;
