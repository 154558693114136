import { css, createGlobalStyle } from 'styled-components';
import Design, { primary } from './Design';
import { Routes } from 'router';
import { Layout, ScrollToTop } from 'components';

const GlobalStyle = createGlobalStyle`
  ${css`
    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Open+Sans:wght@400;500;600;700;800&display=swap');
    #root {
      width: 100%;
    }

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      color: ${primary};
    }

    a {
      text-decoration: none;
      color: ${primary};
    }

    body {
      font-variant-ligatures: none;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      -webkit-font-smoothing: antialiased;
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    body::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    body {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  `}
`;

function App() {
  return (
    <Layout>
      <ScrollToTop />
      <GlobalStyle />
      <Design />
      <Routes />
    </Layout>
  );
}

export default App;
