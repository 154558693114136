import React, { Fragment } from 'react';
import { Row, txtMd, txtSm } from 'Design';
import { Loader, PurchaseItem } from 'components';
import { GET_ALL__PURCHASES } from 'api/queries';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';

const EmptyList = ({ status }) => {
  return (
    <ErrorText>
      {status === 'active'
        ? 'Нет действующих закупок'
        : 'На данный момент архив закупок пуст'}
    </ErrorText>
  );
};

export const PurchasesList = ({ status, sort, category, date }) => {
  const { loading, data, error } = useQuery(GET_ALL__PURCHASES, {
    variables: {
      status: status,
      sortOrder: sort
    }
  });

  if (loading) {
    return (
      <Row justifyContent={'center'} wide alignCenter height={'100px'}>
        <Loader />
      </Row>
    );
  } else if (error) {
    return <EmptyList status={status} />;
  }

  const purchases =
    date &&
    (category === 'uslugi_podryada_na_SMR' ||
      category === 'uslugi' ||
      category === 'postavka_materialov')
      ? data.purchases?.data
          .filter((purchase) => purchase.attributes.category === category)
          .filter(
            (purchase) =>
              new Date(purchase.attributes.start_deadline).getTime() >=
                new Date(date.startDate).getTime() &&
              new Date(purchase.attributes.end_deadline).getTime() <=
                new Date(date.endDate).getTime()
          )
      : category === 'uslugi_podryada_na_SMR' ||
        category === 'uslugi' ||
        category === 'postavka_materialov'
      ? data.purchases?.data.filter(
          (purchase) => purchase.attributes.category === category
        )
      : date
      ? data.purchases?.data.filter(
          (purchase) =>
            new Date(purchase.attributes.start_deadline).getTime() >=
              new Date(date.startDate).getTime() &&
            new Date(purchase.attributes.end_deadline).getTime() <=
              new Date(date.endDate).getTime()
        )
      : data.purchases?.data;

  return (
    <>
      {purchases.length ? (
        purchases.map((item, index) =>
          index === 0 ? (
            <PurchaseItem
              key={index}
              number={item.attributes.number}
              id={item.id}
              item={item.attributes.item}
              date={item.attributes.date}
              organizer={item.attributes.organizer}
              status={status}
              deadline={item.attributes.deadline}
              start_deadline={item.attributes.start_deadline}
              end_deadline={item.attributes.end_deadline}
            />
          ) : (
            <Fragment key={index}>
              <Line />

              <PurchaseItem
                key={index}
                number={item.attributes.number}
                id={item.id}
                item={item.attributes.item}
                date={item.attributes.date}
                organizer={item.attributes.organizer}
                status={status}
                deadline={item.attributes.deadline}
                start_deadline={item.attributes.start_deadline}
                end_deadline={item.attributes.end_deadline}
              />
            </Fragment>
          )
        )
      ) : (
        <EmptyList status={status} />
      )}
    </>
  );
};

const Line = styled.div`
  border-bottom: 2px solid #ececec;
`;

const ErrorText = styled.p`
  padding-top: 117px;
  padding-bottom: 120px;
  ${txtMd};
  text-align: center;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-top: 74px;
    padding-bottom: unset;
    ${txtSm};
  }
`;
