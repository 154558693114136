import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';
import { primaryLt } from '../../../Design';
import arrowRight from '../../../images/icons/arrowRight.svg';

export const Arrow = ({
  left = false,
  small = false,
  large = false,
  noFill = false
}) => {
  return <Wr left={left} small={small} large={large} noFill={noFill} />;
};

const Wr = styled.div`
  width: ${(p) => (p.large ? '40px' : p.small ? '24px' : '36px')};
  height: ${(p) => (p.large ? '40px' : p.small ? '24px' : '36px')};
  border-radius: 100px;
  background-color: ${(p) => (p.noFill ? 'none' : primaryLt)};
  background-image: url(${arrowRight});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: ${(p) => p.left && 'scale(-1, 1)'};
  @media (max-width: ${BREAKPOINTS.sm}) {
    width: 24px;
    height: 24px;
  }
`;
