import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, tabHeader } from 'Design';
import { PurchasesList } from 'components';
import { BREAKPOINTS } from 'utils';
import sortingIcon from 'images/sorting.svg';

export const PurchasesTable = ({ status, category, date }) => {
  const [sortDesc, setSortDesc] = useState(true);

  const handleSortList = () => {
    setSortDesc(!sortDesc);
  };
  return (
    <>
      <StyledRow
        paddingTop={60}
        paddingBottom={18}
        lgPaddingTop={48}
        lgPaddingBottom={20}
        smPaddingTop={40}
        smPaddingBottom={26}
        nowrap
      >
        <TableTitle width={'80px'} noMargin>
          №
        </TableTitle>

        <TableTitle width={'323px'} lgMarginLeft={24}>
          Предмет закупки
        </TableTitle>

        <TableTitle
          width={'186px'}
          marginLeft={'22px'}
          lgMarginLeft={32}
          smHidden
          onClick={handleSortList}
        >
          Дата публикации
          <Icon src={sortingIcon} />
        </TableTitle>

        <TableTitle width={'180px'} lgHidden>
          Срок подачи предложений
        </TableTitle>

        <TableTitle width={'176px'} lgHidden>
          Организатор
        </TableTitle>

        <TableTitle width={'80px'} smHidden smallMargin lgMarginLeft={32}>
          Статус
        </TableTitle>
      </StyledRow>

      <PurchasesList
        status={status}
        sort={sortDesc ? ['date:desc'] : ['date:asc']}
        category={category}
        date={date}
      />
    </>
  );
};

const StyledRow = styled(Row)`
  border-bottom: 2px solid #ececec;
`;

const TableTitle = styled.p`
  margin-left: ${(p) =>
    p.noMargin
      ? 'unset'
      : p.smallMargin
      ? '14px'
      : p.marginLeft
      ? p.marginLeft
      : '40px'};
  max-width: ${(p) => (p.width ? p.width : 'unset')};
  width: 100%;
  display: flex;
  align-items: center;
  ${tabHeader};
  color: #9f9f9f;
  ${(p) => (p.sortStyle ? 'cursor: pointer;' : '')};

  @media (max-width: ${BREAKPOINTS.lg}) {
    ${(p) => (p.lgHidden ? 'display: none;' : '')};
    ${(p) => (p.lgMarginLeft ? `margin-left:${p.lgMarginLeft}px` : '')}
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    ${(p) => (p.smHidden ? 'display: none;' : '')};
    font-size: 16px;
    line-height: 22px;
  }
`;

const Icon = styled.img`
  margin-left: 8px;
  width: 24px;
  height: 24px;
`;
