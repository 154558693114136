import React from 'react';
import {
  Benefits,
  Future,
  MainScreen,
  Mission,
  News,
  Projects
} from './section';

export const MainPage = () => {
  return (
    <>
      <MainScreen />

      <Mission
        paddingTop={110}
        lgPaddingTop={70}
        smPaddingTop={60}
        paddingX={32}
        smPaddingX={16}
      />

      <Projects
        paddingTop={110}
        lgPaddingTop={70}
        smPaddingTop={60}
        paddingX={32}
        smPaddingX={16}
      />

      <Benefits
        paddingTop={120}
        lgPaddingTop={80}
        smPaddingTop={60}
        paddingX={32}
        smPaddingX={16}
      />

      <Future paddingTop={120} lgPaddingTop={80} smPaddingTop={60} />

      <News
        paddingTop={120}
        lgPaddingTop={80}
        smPaddingTop={60}
        paddingX={32}
        smPaddingX={16}
      />
    </>
  );
};
