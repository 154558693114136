import styled from 'styled-components';
import {
  btnPimLg,
  btnPimSm,
  BUTTON_BG,
  BUTTON_HOVER,
  white
} from '../../../Design';

export function PrimaryButton({
  title = 'blank',
  large = false,
  disabled = false,
  onClick = () => {}
}) {
  return (
    <Button large={large} onClick={onClick} disabled={disabled}>
      {title}
    </Button>
  );
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: ${(p) => (p.large ? '50px' : '46px')};
  padding: 12px ${(p) => (p.large ? '36px' : '24px')};
  ${(p) => (p.large ? btnPimLg : btnPimSm)};
  color: ${white};
  border: none;
  outline: none;
  border-radius: 100px;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  opacity: ${(p) => (p.disabled ? '0.4' : '1')};
  ${BUTTON_BG};
  ${(p) => !p.disabled && BUTTON_HOVER};
`;
