import { tabFooterLg, hover, passed } from 'Design';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';
import { formatDateFromStrapi } from 'utils/helpers';

export const Date = ({ date }) => {
  return <Text className='date'>{formatDateFromStrapi(date)}</Text>;
};

const Text = styled.span`
  ${tabFooterLg}
  text-transform: none;
  &:hover {
    color: ${hover};
  }
  &:active {
    color: ${passed};
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 14px;
    line-height: 20px;
  }
`;
