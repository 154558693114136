import React from 'react';
import { Contacts, Main, Maps } from './section';

export const ContactsPage = () => {
  return (
    <>
      <Main
        paddingTop={116}
        paddingX={32}
        mdPaddingX={16}
        xlPaddingTop={112}
        smPaddingTop={96}
      />
      <Contacts
        paddingTop={120}
        paddingX={32}
        mdPaddingX={16}
        mdPaddingTop={70}
        smMarginTop={62}
      />
      <Maps
        paddingTop={72}
        paddingX={32}
        mdPaddingX={16}
        mdPaddingTop={90}
        smMarginTop={72}
      />
    </>
  );
};
