import styled from 'styled-components';
import { primary, SEC_BUTTON_HOVER, white } from '../../../Design';
import { btnSecLg, btnSecMd, btnSecSm, btnPimSm } from '../../../Design/Fonts/index';
import { BREAKPOINTS } from 'utils';

export function SecondaryButton({
  title = 'blank',
  light,
  onClick = () => {},
  disabled = false
}) {
  return (
    <Button onClick={onClick} disabled={disabled} light={light}>
      {title}
    </Button>
  );
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: ${(p) => (p.light ? white : primary)};
  ${btnPimSm};
  font-weight: 400;
  background: none;
  border: none;
  outline: none;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  opacity: ${(p) => (p.disabled ? '0.4' : '1')};
  ${(p) => !p.disabled && SEC_BUTTON_HOVER};
  /* @media (max-width: ${BREAKPOINTS.md}) {
    ${btnSecMd};
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    ${btnSecSm};
  } */
`;
