import { Container, h3h, h4h, hover, Img, Row, txtLg, txtXm } from 'Design';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS, getImageUrl } from 'utils';
import shadow from 'images/Home/News/shadow.png';
import { Link } from 'react-router-dom';
import { Date, Description, Text, Title } from 'components';

export const Preview = ({ data, big = false }) => {
  const imgUrl = getImageUrl(data.attributes.img?.data?.attributes?.url);
  return big ? (
    <StyledBigLink to={`/news/${data.id}`}>
      <BG bg={imgUrl}>
        <Shadow>
          <Row
            paddingX={50}
            xlPaddingX={16}
            alignCenter
            justifyContent={'center'}
            height={'100%'}
            direction={'column'}
          >
            <TitleBig>
              {data.attributes.title.length < 50
                ? data.attributes.title
                : `${data.attributes.title?.substr(0, 50)}...`}
            </TitleBig>
            {data.attributes.description && (
              <DescriptionBig>
                {data.attributes.description.length < 100
                  ? data.attributes.description
                  : `${data.attributes.description?.substr(0, 100)}...`}
              </DescriptionBig>
            )}
          </Row>
        </Shadow>
      </BG>
    </StyledBigLink>
  ) : (
    <StyledLink to={`/news/${data.id}`}>
      <StyledImg maxWidth={'300px'} src={imgUrl} marginBottom={24} />
      <StyledContainer maxWidth={300}>
        <Container marginBottom={8}>
          <Title
            title={
              data.attributes.title.length < 50
                ? data.attributes.title
                : `${data.attributes.title?.substr(0, 50)}...`
            }
          />
        </Container>
        <Container marginBottom={16}>
          <Date date={data.attributes.date} />
        </Container>
        {data.attributes.description && (
          <Text
            text={
              data.attributes.description.length < 100
                ? data.attributes.description
                : `${data.attributes.description?.substr(0, 100)}...`
            }
          />
        )}
      </StyledContainer>
    </StyledLink>
  );
};

const TitleBig = styled.p`
  margin-bottom: 16px;
  ${h4h}
  color: #fff;
  @media (max-width: ${BREAKPOINTS.xl}) {
    font-size: 30px;
    line-height: 36px;
  }
`;

const DescriptionBig = styled.p`
  ${txtXm}
  color: #fff;
`;

const StyledBigLink = styled(Link)`
  &:hover ${TitleBig}, &:hover ${DescriptionBig} {
    color: ${hover};
  }
`;

const BG = styled.div`
  max-width: 520px;
  width: 100%;
  height: 476px;
  ${(p) => (p.bg ? `background-image: url(${p.bg});` : '')}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 384px;
  }
`;

const Shadow = styled(BG)`
  width: 100%;
  height: 477px;
  background-color: rgba(0, 0, 0, 0.5); 
  /* background-image: url(${shadow}); */
  /* background-position: center; */
  @media (max-width: ${BREAKPOINTS.md}) {
    background-position: center;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 385px;
  }
`;

const StyledLink = styled(Link)`
  max-width: 300px;
  &:hover .title,
  &:hover .date,
  &:hover .text {
    color: ${hover};
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    max-width: unset;
  }
`;

const StyledContainer = styled(Container)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    max-width: unset;
  }
`;

const StyledImg = styled(Img)`
  min-height: 200px;
  object-fit: cover;
  border-radius: 20px;
  @media (max-width: ${BREAKPOINTS.sm}) {
    max-width: unset;
  }
`;
