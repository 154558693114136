import {
  Col,
  Container,
  descrJobText,
  h3h,
  h5h,
  headLg,
  Img,
  Row,
  txtXm
} from 'Design';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';
import icon1 from 'images/Home/Benefits/icon1.png';
import icon2 from 'images/Home/Benefits/icon2.png';
import icon3 from 'images/Home/Benefits/icon3.png';
import icon4 from 'images/Home/Benefits/icon4.png';

const collection = [
  {
    img: icon1,
    title: 'Высокие компетенции в сфере ГЧП',
    text: 'Компетенции по подготовке инициативных предложений в рамках процедуры ГЧП: подготовка соглашений, финансовых моделей и иных документов в соответствии с требованиями законодательства в сфере ГЧП/концессий. '
  },
  {
    img: icon2,
    title: 'Команда профессионалов',
    text: 'У нас более 300 высококлассных специалистов различных направлений дорожного строительства, имеющих серьезный профессиональный опыт работы в разных регионах России.'
  },
  {
    img: icon3,
    title: 'Укомплектованный автопарк',
    text: 'Автопарк, включающий около 200 единиц специализированной дорожной и строительной техники, для выполнения всех видов дорожно-строительных работ.'
  },
  {
    img: icon4,
    title: 'Проведение исследовательских работ',
    text: 'Собственная дорожная лаборатория и геодезическая служба для проведения исследовательских работ на территории строительства.'
  }
];

const Block = ({ data }) => {
  return (
    <ContainerBlock maxWidth={464}>
      <Row nowrap alignCenter marginBottom={16} gap={6}>
        <StyledImg src={data.img} />
        <Subtitle>{data.title}</Subtitle>
      </Row>
      <Text>{data.text}</Text>
    </ContainerBlock>
  );
};

export const Benefits = (props) => {
  return (
    <Wrapper {...props} maxWidth={1180}>
      <Title>Наши преимущества</Title>
      {/* <Description>Чем мы отличаемся от конкурентов</Description> */}
      <StyledRow
        justifyContent={'center'}
        nowrap
        marginY={60}
        xlMarginY={50}
        smMarginY={30}
      >
        {collection.slice(0, 2).map((item, index) =>
          index === 0 ? (
            <Col key={index} width={'50%'}>
              <Block data={item} />
            </Col>
          ) : (
            <StyledCol key={index} width={'50%'}>
              <Block data={item} />
            </StyledCol>
          )
        )}
      </StyledRow>

      <StyledRow nowrap justifyContent={'center'}>
        {collection.slice(2, 4).map((item, index) =>
          index === 0 ? (
            <Col key={index} width={'50%'}>
              <Block data={item} />
            </Col>
          ) : (
            <StyledCol key={index} width={'50%'}>
              <Block data={item} />
            </StyledCol>
          )
        )}
      </StyledRow>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const Title = styled.span`
  ${h5h}
  background: linear-gradient(272.25deg, #2E61E3 2.99%, #30CAE2 96.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const Description = styled.h3`
  ${h3h}
  @media (max-width: ${BREAKPOINTS.lg}) {
    font-size: 30px;
    line-height: 36px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const StyledRow = styled(Row)`
  gap: 100px;
  @media (max-width: ${BREAKPOINTS.xl}) {
    gap: 20px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    flex-direction: column;
    gap: 30px;
    & > div {
      max-width: unset;
    }
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const ContainerBlock = styled(Container)`
  @media (max-width: ${BREAKPOINTS.sm}) {
    max-width: unset;
  }
`;

const StyledImg = styled(Img)`
  max-width: 115px;
  @media (max-width: ${BREAKPOINTS.xl}) {
    max-width: 84px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    max-width: 70px;
  }
`;

const Subtitle = styled.p`
  ${headLg}
  @media (max-width: ${BREAKPOINTS.xl}) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const Text = styled.p`
  ${txtXm};
  @media (max-width: ${BREAKPOINTS.xl}) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 16px;
    line-height: 22px;
  }
`;
